<template>
    <layout class="wrapper">
        <!--  内容部分  -->
        <div class="content">
            <div class="flex x-left mobile-bread">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/special-ma/index/'+$route.params.id }">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/special-ma/guide-list/'+$route.params.id }">临床指南</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '' }">指南列表</router-link>
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="flex x-left content-box mt15">
                <div class="left-box">
                    <a-spin :spinning="loading" tip="Loading...">
                        <div class="list-items">
                            <text-item :path="'/special-ma/guide-detail/'+$route.params.id" type="2" gap="20" v-for="(item,index) in listData" :key="index" :item="item" specialType="ma"></text-item>
                            <a-empty v-if="!listData.length && !loading" />
                        </div>
                    </a-spin>
                    <div class="t-c mt30 guide-pagination" v-if="listCount > 0">
                        <a-pagination :total="listCount" v-model:current="params.page" :page-size="params.limit" :show-size-changer="false" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                    </div>
                </div>
                <div class="right-box flex-item">
                    <layout-right :config="layoutRightConfig"></layout-right>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index-ma';
import layoutRight from '@/components/layout/layout-right';
import textItem from '@/components/text-item.vue';
import { committee_id } from '@/config';
import { jsonFormat } from '@/utils/jsonFormat.js';
export default {
    name: 'SpecialMaGuideList',
    components: {
        layout, textItem, layoutRight
    },
    data() {
        return {
            loading: true,
            params: {
                limit: 15, 
                topic_id: this.$route.params.id, 
                page: 1,
                topic_committee_id:committee_id
            },
            listCount: 0,
            listData: [],
            layoutRightConfig: {
                // 相关资讯
                news: {
                    show: true, // 是否显示
                    methods: 'post',
                    type: 'ma',
                    title: '相关资讯', // 标题
                    moreLink:'/special-ma/infomation-list/'+this.$route.params.id,
                    detailLink:'/special-ma/infomation-detail/'+this.$route.params.id,
                    data: {
                        page: 1,
                        limit: 5, 
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id,
                        
                    },   // 参数条件
                },
                meeting: {
                    show: true, // 是否显示
                    title: '相关会议', // 标题
                    methods: 'get',
                    moreLink: '/special-ma/meeting-list/'+this.$route.params.id,
                    type: 'ma',
                    data: {
                        page: 1,
                        pageSize: 6,
                        topic_id: this.$route.params.id, 
                        topic_committee_id:committee_id
                    },   // 参数条件
                },
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    type: 'ma',
                    title: '相关视频', // 标题
                    moreLink: '/special-ma/video-list/'+this.$route.params.id,
                    data: {
                        page: 1,
                        PageSize: 4,
                        no_count: false,
                        topic_id: this.$route.params.id, 
                        topic_committee_id: committee_id
                    },   // 参数条件
                }
            },
        }
    },
    created() {
        this.getGuideList()
    },
    // 缓存页面二次进入的钩子函数  动态参数更新时开启
    activated() {
        // this.getGuideList();
    },
    methods: {
        // 获取临床指南列表
        getGuideList() {
            this.loading = true;
            this.request.post('GuideList', this.params).then(res => {
                let obj = {
                    id: 'guide_id',
                    news_title: 'title_cn',
                    create_time: 'publish_date',
                    source: 'reference',
                    
                }
                this.listCount = Number(res.data.count)
                let listData = jsonFormat(res.data.list, obj)
                listData.forEach(item => {
                    item.class_type = 0
                    item.typeName = '指南'
                })
                this.listData = listData
                this.loading = false
            })
        },
        // 页码切换
        handlePageChangeEvent(page, pageSize) {
            window.scrollTo(0,0);
            this.params.page = page
            this.params.limit = pageSize
            this.getGuideList()
        },
    },
}
</script>
<style lang="less" scoped>
.content {
    width: 1200px;
    padding: 15px 0;
    box-sizing: border-box;
    margin: 0 auto;
    .left-box {
        width: 770px;
        .list-items {
            min-height: 500px;
        }
    }
    .right-box {
        margin-left: 32px;
        margin-top: -10px;
    }
}
@media screen and (max-width:768px){
    .content{
        width: 100%;
        padding: 10px 10px 0;
        .mobile-bread{
            display: none;
        }
        .content-box{
            display: block;
        }
        .left-box{
            width: 100%;
            .list-items{
                min-height: 0;
            }
            .guide-pagination{
                margin-top: 0;
            }
        }
        .right-box{
            margin-left: 0;
            margin-top: 60px;
        }
    }
}
</style>
